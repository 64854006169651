.search-plan-storey {
	height: auto !important;
	background-color: transparent !important;
}

.search-plan-header {
	margin: auto;
	text-align: center;
	font-size: 38px;
	font-weight: 600;
	color: var(--text-lightest-color);
}

.search-plan-header-hightlighter {
	color: var(--text-hightlighter-lighter-color);
}

.search-plan-subheader {
	margin: auto;
	text-align: center;
	font-size: 14px;
	color: var(--text-lighter-color);
}

.search-plan-tagline {
	margin: auto;
	text-align: center;
	font-family: var(--app-font-light);
	font-size: 12px;
	color: var(--text-light-color);
}

.search-plan-purchase-arc-button {
	margin: auto;
	border-color: var(--text-lighter-color);
	color: var(--text-lighter-color);
	height: 34px;
	max-height: 34px;
	padding-left: 22px;
	padding-right: 22px;
	font-size: 14px;
}
.search-plan-purchase-arc-button:hover {
	background: transparent;
}

.search-plan-table-row {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 600px;
	height: 260px;
	margin: auto;
}

.search-plan-box {
	width: 50%;
	height: 100%;
	background-color: rgba(255, 255, 255, 1.0);
	box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
	text-align: center;
	padding: 10px;
}

.search-plan-box-basic {
	height: 75%;
	z-index: 10;
	border-radius: 6px 0px 0px 6px;
}

.search-plan-box-premium {
	z-index: 11;
	border-radius: 6px;
}

.search-plan-box-free {
	height: 100%;
	z-index: 10;
	border-radius: 6px;
}

.search-plan-name {
	font-family: var(--app-font-bold);
	font-size: 18px;
}

.search-plan-amount {
	font-family: var(--app-font-bold);
	font-size: 36px;
	margin: auto;
}

.search-plan-details-row {
	display: flex;
	align-items: top;
	width: 600px;
	margin: auto;
	justify-content: center;
}

.search-plan-details-box {
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 50%;
	padding-left: 30px;
}

.search-plan-details-feature {
	font-family: var(--app-font-light);
	font-size: 14px;
}
