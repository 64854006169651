.subscription-details-container {
	display: flex;
	flex-direction: column;
	align-self: center;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 400px;
}


.subscription-details-basic-container {
	flex-direction: row;
	gap: 40px;
}

.subscription-details-basic-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.subscription-details-basic-plan-content {
	width: 350px;
	min-width: 350px;
	max-width: 350px;
}

.subscription-details-upgrade-content {
	padding: 20px 0px;
	width: 400px;
	min-width: 400px;
	max-width: 400px;
}

.subscription-details-vertical-separator {
	border-right: 1px solid var(--input-border-color);
	width: 1px;
	min-width: 1px;
	max-width: 1px;
	height: 100%;
}

.subscription-details-icon {
	align-self: center;
	width: 120px;
	height: 120px;
	line-height: 120px;
	background-size: 120px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-image: url(../../third-party/images/subscription-details-icon.svg);
}


.subscription-details-title {
	font-size: var(--font-size-big);
	color: var(--text-darkest-color);
}


.subscription-details-summary {
	font-size: var(--font-size-normal);
	color: var(--text-darker-color);
}


.subscription-details-upgrade-question {
	font-size: var(--font-size-normal);
	color: var(--text-darker-color);
}


.subscription-details-upgrade-title {
	font-size: var(--font-size-biggest);
	color: var(--text-darkest-color);
}


.subscription-details-upgrade-tagline {
	font-size: var(--font-size-normal);
	color: var(--text-darkest-color);
}


.subscription-details-benefit-box {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.subscription-details-benefit-feature {
	font-family: var(--app-font-light);
	font-size: 14px;
	text-align: left;
}


.subscription-order-summary-box {
	width: 280px;
	height: auto;
	background-color: rgba(255, 255, 255, 1.0);
	box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;
	text-align: center;
	padding: 30px 20px;
	z-index: 11;
	border-radius: 8px;
}

.subscription-order-summary-plan-name {
	font-family: var(--app-font-light);
	font-weight: bold;
	font-size: 21px;
}

.subscription-order-summary-duration {
	font-family: var(--app-font-normal);
	font-size: 13px;
}

.subscription-order-summary-total {
	font-family: var(--app-font-light);
	font-size: 16px;
	color: var(--text-darker-color);
}

.subscription-order-summary-amount {
	font-family: var(--app-font-normal);
	font-size: 40px;
	font-weight: bold;
	margin: auto;
}
