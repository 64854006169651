.signup-login-storey {
	height: auto !important;
	background-color: transparent !important;
	justify-content: center;
	height: 100% !important;
}

.signup-login-box {
	display: flex;
	flex-direction: row;
	width: 650px;
	margin: auto;
	overflow: hidden;
	background-color: rgba(255, 255, 255, 1.0);
	border-radius: 8px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.signup-login-form {
	max-width: 320px;
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 25px 35px 35px 35px;
	gap: 20px;
}

.signup-login-form-heading {
	font-size: 20px;
	color: rgba(104, 121, 133, 1.0);
	text-align: center;
}

.signup-login-form-button {
	width: 100%;
	height: 44px;
	border-radius: 8px;
	background-color: transparent;
}

.signup-login-form-instruction {
	font-size: 13px;
	text-align: center;
	color: rgba(104, 121, 133, 1.0);
}

.login-forgot-password-form-container {
	flex: 1;
	display: flex;
	flex-direction: column;
}


/* @pragmamark: Vertical Separator */

.signup-login-vertical-separator-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.signup-login-vertical-separator-line {
	flex-grow: 1;
	width: 1px;
	background-color: var(--input-border-color);
}

.signup-login-vertical-separator-word {
	height: 28px;
	line-height: 28px;
	color: var(--input-border-color);
}

.signup-login-horizontal-separator-line {
	flex-grow: 1;
	height: 1px;
	min-height: 1px;
	max-height: 1px;
	background-color: var(--input-border-color);
}
