
.ceiling-bar {
	display: flex;
	height: 80px;
	min-height: 80px;
	max-height: 80px;
	background-color: rgba(244, 244, 248, 1.0);
	border-bottom: 1px solid var(--box-border-color);
	padding: 0px 20px;
	align-items: center;
}


.ceiling-bar-spacer {
	flex: 1;
}


.ceiling-bar-header {
	font-size: 28px;
}


.ceiling-bar-user-box {
	display: flex;
	flex-direction: column;
	text-align: end;
}

.ceiling-bar-user-text {
	font-size: 16px;
	color: var(--header-color);
}

.ceiling-bar-user-subtext {
	font-size: 13px;
	color: var(--text-darker-color);
}


.ceiling-bar-logout-button {
	display: inline-block;
	width: 48px;
	height: 48px;
	background-color: var(--text-darkest-color);
	mask-size: cover;
	-webkit-mask-size: cover;
	mask-image: url(../../third-party/images/logout.svg);
	cursor: pointer;
}

.ceiling-bar-logout-button:active {
	transform: translate(0px, 1px);
	-webkit-transform: translate(0px, 1px);
}
