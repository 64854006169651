.product-details-section-title {
	font-size: var(--font-size-big);
	color: var(--text-darkest-color);
	font-weight: 500;
}

.product-details-role-cell {
	width: 20%;
	max-width: 20%;
}

.product-details-cta-cell {
	width: 20%;
	min-width: 20%;
	max-width: 20%;
}


.product-details-text-field {
	max-width: 300px;
	height: 34px;
	font-size: 13px;
}


.product-details-arc-button {
	min-width: 100px;
	max-width: 400px;
}


.api-key-date-cell {
	flex: 1;
}

.api-key-message-row {
	border-top-style: dashed;
}

.api-key-message-cell {
	line-height: 18px;
	color: var(--text-highlight-color);
}


.product-details-row-separator {
	height: 20px;
	border-bottom: 1px dotted var(--text-darkest-color);
}
.product-details-row-separator .app-separator-horizontal {
	border-top: 1px dashed var(--input-border-color);
}


.ios-app-specification-row:not(:first-child):not(:last-child) {
	border: 0px;
}
.ios-app-specification-row:last-child {
	border-top: 0px;
}
.ios-app-specification-row:hover {
	background-color: transparent !important;
	cursor: default !important;
}
.ios-app-specification-cell {
	flex: initial;
}


.android-app-specification-row:not(:first-child):not(:last-child) {
	border: 0px;
}
.android-app-specification-row:last-child {
	border-top: 0px;
}
.android-app-specification-row:hover {
	background-color: transparent !important;
	cursor: default !important;
}
.android-app-specification-cell {
	flex: initial;
}