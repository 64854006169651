.support-details-storey {
	height: auto !important;
	background-color: transparent !important;
}

.support-details-header {
	margin: auto;
	text-align: center;
	font-size: 38px;
	font-weight: 600;
	color: var(--text-lightest-color);
}

.support-details-header-hightlighter {
	color: var(--text-hightlighter-lighter-color);
}

.support-details-subheader {
	margin: auto;
	text-align: center;
	font-size: 14px;
	color: var(--text-lighter-color);
}


.support-details-table-row {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 900px;
	height: 350px;
	margin: auto;
}

.support-details-box {
	width: 50%;
	height: 100%;
	background-color: rgba(255, 255, 255, 1.0);
	box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
	text-align: center;
	padding: 10px;
}

.support-details-box-email {
	z-index: 10;
	padding: 15px 40px;
	height: 90%;
	border-radius: 6px;
}

.support-details-box-header {
	font-family: var(--app-font-bold);
	font-size: 18px;
}

.support-details-email-image {
	display: inline-block;
	width: 300px;
	height: 50px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	background-image: url(../../assets/images/email-aurvan-support.svg);
}

.support-details-box-note {
	font-family: var(--app-font-light);
	font-size: 14px;
}
