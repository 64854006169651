
.at-quick-look-view-overlay, .at-quick-look-view-overlay * {
	border-color: #CCCCCC;
}

.at-quick-look-view-overlay {
	width: 100%;
	height: 100%;
	z-index: 10;
	top: 0;
	left: 0;
	position: fixed;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.5);
}

.at-quick-look-view-loading-indicator {
	border: 8px solid #EEEEEE;
	border-top: 8px solid #999999;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	display: block;
	position: absolute;
	top: calc(50% - 25px);
	left: calc(50% - 25px);
	animation: spin 2s linear infinite;
	z-index: 1;
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

.at-quick-look-view-content {
	border-radius: 5px;
	object-fit: contain;
	display: block;
	background-color: #FFFFFF;
	background: #FFFFFF;
	margin: auto;
	width: 100px;
	height: 100px;
	padding: 10px;
	transition: width 500ms, height 500ms;
}
