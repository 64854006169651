@font-face { font-family: MulishRegular; src: url(../../third-party/fonts/mulish/Mulish-Regular.ttf); }
@font-face { font-family: MulishMedium; src: url(../../third-party/fonts/mulish/Mulish-Medium.ttf); }
@font-face { font-family: MulishLight; src: url(../../third-party/fonts/mulish/Mulish-Light.ttf); }

:root {
	--selection-text-color: rgba(255, 255, 255, 1.0);
	--selection-background-color: rgb(253, 186, 29);

	--font-size-biggest: 23px;
	--font-size-bigger: 20px;
	--font-size-big: 17px;
	--font-size-normal: 14px;
	--font-size-small: 11px;
	--font-size-smaller: 8px;
	--font-size-smallest: 5px;

	--page-background-color: rgba(247, 247, 247, 1.0);

	--header-color:rgba(0, 50, 20, 1.0);
	
	--text-darkest-color: rgba(0, 0, 0, 1.0);
	--text-darker-color: rgba(100, 100, 100, 1.0);
	--text-dark-color: rgba(150, 150, 150, 1.0);
	--text-lightest-color: rgba(255, 255, 255, 1);
	--text-lighter-color: rgba(255, 255, 255, 0.80);
	--text-light-color: rgba(255, 255, 255, 0.60);
	--text-highlight-color: rgb(255, 136, 0);

	--text-hightlighter-lighter-color: rgba(247, 212, 124, 1.0);
	--text-hightlighter-light-color: rgba(234, 200, 123, 1.0);

	--link-title-color: rgba(0, 100, 200, 1);
	--link-title-hover-color: rgba(25, 25, 112, 1);

	--input-title-color: rgba(123, 125, 127, 1);
	--input-value-color: rgba(50, 53, 56, 1);
	--input-placeholder-color: rgba(137, 144, 152, 1);
	--input-border-color: rgba(204, 210, 216, 1);
	--input-background-color: rgba(255, 255, 255, 1);
	--input-background-hover-color: rgba(255, 232, 177, 1);

	--button-title-color: rgb(198, 139, 0);
	--button-title-disabled-color: rgb(155, 140, 105);
	--button-title-destructive-color: rgb(199, 93, 79);

	--button-border-color: rgb(198, 139, 0);
	--button-border-disabled-color: rgb(155, 140, 105);
	--button-border-destructive-color: rgb(199, 93, 79);

	--button-background-hover-color: var(--input-background-hover-color);
	--button-background-hover-destructive-color: rgb(255, 218, 216);

	--table-title-color: rgba(33, 35, 37, 1);
	--table-border-color: rgba(225, 227, 230, 1);
	--table-row-hover-color: rgba(250, 250, 254, 1);

	--box-border-color: rgba(225, 227, 230, 1);
	--code-background-color: rgba(246, 248, 250, 1);

	--app-font-light: MulishLight, Helvetica, Arial, Verdana, sans-serif;
	--app-font-normal: MulishRegular, Helvetica, Arial, Verdana, sans-serif;
	--app-font-bold: MulishMedium, Helvetica, Arial, Verdana, sans-serif;
}


html {
	margin: 0px;
	padding: 0px;
	border: 0px;
}

body {
	font-family: var(--app-font-normal);
	font-size: var(--font-size-normal);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--page-background-color);
	margin: 0px;
	padding: 0px;
}

* {
	/* border-box allows padding from inside. */
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	outline-style: none;
}

/* Don't show shadows when selecting text */
::-moz-selection { background: var(--selection-background-color); color: var(--selection-text-color); text-shadow: none; }
::selection { background: var(--selection-background-color); color: var(--selection-text-color); text-shadow: none; }



/** @pragmamark - App Input Style **/

.app-input-field {
	width: 100%;
	height: 40px;
	font-size: var(--font-size-normal);
	line-height: 34px;
	padding: 0px 10px 0px 10px;
	border: 1px solid var(--input-border-color);
	border-radius: 4px;
	background-color: var(--input-background-color);
	padding: 0px 10px 0px 10px;
	color: var(--input-value-color);

	-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.app-input-title {
	font-size: var(--font-size-big);
	color: var(--input-title-color);
	font-weight: 500;
}

.app-icon {
	display: inline-block;
	width: 24px;
	height: 24px;
	mask-size: cover;
	-webkit-mask-size: cover;
	/* mask-image: url(../../third-party/images/image.svg); */
	/* -webkit-mask-image: url(../../third-party/images/image.svg); */
	background-color: var(--text-darkest-color);
}

.app-link {
	display: flex;
	gap: 5px;
	align-items: center;
	color: var(--link-title-color);
	text-decoration: none;
	cursor: pointer;
}
.app-link * {
	display: inline-block;
}
.app-link:hover {
	color: var(--link-title-hover-color);
}
.app-link:active {
	transform: translate(0px, 1px);
	-webkit-transform: translate(0px, 1px);
}

.app-link-icon {
	display: inline-block;
	width: 12px;
	height: 12px;
	background-color: var(--link-title-color);
	mask-size: cover;
	-webkit-mask-size: cover;
	/* mask-image: url(../../third-party/images/image.svg); */
	/* -webkit-mask-image: url(../../third-party/images/image.svg); */
}
.app-link:hover .app-link-icon {
	background-color: var(--link-title-hover-color);
}

.help-note-button {
	display: inline-block;
	width: 12px;
	height: 12px;
	background-color: var(--link-title-color);
	mask-size: cover;
	-webkit-mask-size: cover;
	mask-image: url(../../third-party/images/help-note.svg);
	cursor: pointer;
	align-self: baseline;
}
.help-note-button:active {
	transform: translate(0px, 1px);
	-webkit-transform: translate(0px, 1px);
}

/** @pragmamark - Scroll View Style **/

.vertical-scroll-view {
	overflow: hidden;
	overflow-y: auto;
	scroll-behavior: smooth;
}

.vertical-scroll-view::-webkit-scrollbar {
	width: 5px;
}

.vertical-scroll-view:hover::-webkit-scrollbar-thumb {
	background: #BBBBBB;
	border-radius: 2px;
}

.vertical-scroll-view:hover::-webkit-scrollbar-track {
	background: #EEEEEE;
	border-radius: 2px;
}


/** @pragmamark - No Data View Style **/

.no-data-view {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	text-align: center;
	vertical-align: middle;
	font-size: 15px;
	color: var(--text-dark-color);
	background-color: var(--input-background-color);
}


/** @pragmamark - Separator Style **/

.app-separator-vertical {
	border-right: 1px solid var(--input-border-color);
	width: 1px;
	min-width: 1px;
	max-width: 1px;
	height: 100%;
}

.app-separator-horizontal {
	border-top: 1px solid var(--input-border-color);
	width: 100%;
	height: 1px;
	min-height: 1px;
	max-height: 1px;
}
