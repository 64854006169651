.customer-profile-details-section-title {
	font-size: var(--font-size-big);
	color: var(--text-darkest-color);
	font-weight: 500;
}

.customer-profile-details-cta-cell {
	width: 15%;
	min-width: 15%;
	max-width: 15%;
}


.customer-profile-details-text-field {
	max-width: 300px;
	height: 34px;
	font-size: 13px;
}


.customer-profile-details-arc-button {
	min-width: 100px;
	max-width: 400px;
}


.customer-identity-row:hover {
	background-color: transparent !important;
	cursor: default !important;
}
.customer-identity-email-cell {
	width: 60%;
	min-width: 60%;
	max-width: 60%;
}
.customer-identity-date-cell {
	width: 20%;
	min-width: 20%;
	max-width: 20%;
	justify-content: center;
}
.customer-identity-cta-cell {
	width: 20%;
	min-width: 20%;
	max-width: 20%;
	justify-content: center;
}

.update-customer-password-row:not(:first-child):not(:last-child) {
	border: 0px;
}
.update-customer-password-row:last-child {
	border-top: 0px;
}
.update-customer-password-row:hover {
	background-color: transparent !important;
	cursor: default !important;
}
.update-customer-password-cell {
	flex: initial;
}