
.foundation-slab {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100vw;
	min-width: 100vw;
	max-width: 100vw;
	min-height: calc(100vh); /* 1vh = 1% of browser screen height */
	max-height: calc(100vh);
	height: calc(100vh);
	background-color: transparent;
}


.foundation-tiles {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100vw;
	height: 100vh;
	z-index: -1;
	background-color: rgba(100, 70, 200, 1.0);
	clip-path: polygon(0% 0%, 100% 0%, 100% 45%, 85% 56%, 65% 47%, 40% 49%, 25% 55%, 9% 54%, 0% 50%);
	-webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 45%, 85% 56%, 65% 47%, 40% 49%, 25% 55%, 9% 54%, 0% 50%);
}


.foundation-plinth {
	display: flex;
	flex-direction: column;
	width: calc(100vw - 140px);
	min-width: calc(100vw - 140px);
	max-width: calc(100vw - 140px);
	height: 100%;
}


.foundation-basement-bordered {
	display: flex;
	flex-direction: row;
	width: calc(100vw - 140px);
	min-width: calc(100vw - 140px);
	max-width: calc(100vw - 140px);
	height: 100%;
	margin-bottom: 40px;
	backdrop-filter: blur(25px);
	box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
	border-radius: 10px;
	overflow: hidden;
}


.foundation-building {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}


.foundation-storey {
	display: flex;
	flex-direction: column;
	padding: 20px;
	background-color: rgba(255, 255, 255, 1.0);
	height: 100%;
}


.app-version {
	font-size: 10px;
	color: rgba(255, 255, 255, 1.0);
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	padding: 3px 5px 3px 5px;
}