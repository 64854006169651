
.app-select-field {
	cursor: pointer;
	padding: 0px;
	position:relative;
	transition: background-color 0.5s ease;
	z-index: 1000;
}

.app-select-field:hover {
	background-color: var(--input-background-hover-color);
}

.app-select-field:active {
	transform: translate(0px, 1px);
	-webkit-transform: translate(0px, 1px);
}


.app-select-field-title {
	display: flex;
	align-items: center;
	gap: 8px;
	width: 100%;
	height: 100%;
	line-height: 100%;
	padding: 0px 10px;
	font-size: 14px;
	color: var(--input-value-color);
	text-align: left;
	cursor: pointer;
	background: transparent;
	border: none;
	white-space: nowrap;
	overflow: hidden;
}


.app-select-field-dropdown {
	margin-top: 2px;
	display: none;
	position: absolute;
	width: 100%;
	min-width: 160px;
	max-height: 200px;
	overflow-y: auto;
	background-color: var(--input-background-color);
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	z-index: 1;
	border: 1px solid var(--input-border-color);
	border-radius: 4px;
}

.app-select-field-selected .app-select-field-dropdown {
	display: block;
}

.app-select-field-option {
	display: flex;
	height: 48px;
	line-height: 48px;
	padding: 0px 16px;
	gap: 8px;
	align-items: center;
	font-size: 14px;
	transition: background-color 0.5s ease;
}

.app-select-field-option:hover {
	background-color: var(--input-background-hover-color);
}
