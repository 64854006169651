.page-not-found-storey {
	width: 100%;
}


.page-not-found-header {
	margin: 0px auto;
	text-align: center;
	font-size: 38px;
	font-weight: 600;
	color: var(--text-darker-color);
}

.page-not-found-header-hightlighter {
	color: var(--text-hightlighter-lighter-color);
}


.page-not-found-subheader {
	margin: 0px auto;
	text-align: center;
	font-size: 20px;
	color: var(--text-dark-color);
}
