.app-button {
	width: auto;
	height: auto;
	background-color: transparent;
	border: 1px solid var(--button-border-color);
	border-radius: 4px;
	font-family: var(--app-font-bold);
	font-size: 14px;
	padding: 4px 8px;
	color: var(--button-title-color);
	cursor: pointer;
	transition: background-color 0.5s ease;
}

.app-button:hover {
	background-color: var(--button-background-hover-color);
}

.app-button:active {
	transform: translate(0px, 1px);
	-webkit-transform: translate(0px, 1px);
}

.app-button:disabled {
	color: var(--button-title-disabled-color);
	border: 1px solid var(--button-border-disabled-color);
}

.app-button:disabled:hover {
	background-color: transparent;
}

.app-button:disabled:active {
	transform: none;
	-webkit-transform: none;
}


.app-button-submit {
	height: 40px;
}


.app-button-arc-rect {
	height: 28px;
	min-height: 28px;
	max-height: 28px;
	border-radius: 9999px; /* 9999px will top out at the maximum size that allows circular corners. */
	font-size: 13px;
	padding: 0px 14px;
}


.app-button-destructive {
	color: var(--button-title-destructive-color);
	border: 1px solid var(--button-border-destructive-color);
}

.app-button-destructive:hover {
	background-color: var(--button-background-hover-destructive-color);
}
