.search-knowledgebase-storey {
	width: 100%;
}

.search-knowledgebase-page {
	width: 100%;
	max-width: 900px;
	margin: 0px auto;
}

.search-knowledgebase-screenshot-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 30px;
	margin: 20px auto;
	width: fit-content;
}

.search-knowledgebase-screenshot {
	background-color: #FFFFFF;
	height: 360px;
	min-height: 360px;
	max-height: 360px;
	padding: 8px;
	border-radius: 4px;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	cursor: pointer;
}
