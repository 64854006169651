/** @pragmamark - Navigation Bar Style **/

.navigation-bar {
	display: flex;
	flex-direction: column;
	width: 250px;
	max-width: 250px;
	height: 100%;
	background-color: rgba(244, 244, 248, 1.0);
	border-right: 1px solid var(--box-border-color);
	z-index: 1200;
}


/** @pragmamark - Navigation Bar Header **/

.navigation-bar-header {
	display: flex;
	flex-direction: row;
	height: 80px;
	min-height: 80px;
	padding: 0px 20px;
	border-bottom: 1px solid var(--box-border-color);
}

.navigation-bar-header-icon {
	align-self: center;
	width: 40px;
	height: 40px;
	line-height: 34px;
	background-size: 34px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-image: url(../../third-party/images/nav-bar-app-logo.svg);
}

.navigation-bar-header-text {
	align-self: center;
	font-size: 24px;
	color: var(--header-color);
}


/** @pragmamark - Navigation Bar Table **/

.navigation-bar-table {
	padding: 0px 20px;
	height: 100%;
	color: rgba(117, 125, 138, 1);
}


/** @pragmamark - Navigation Bar Row **/

.navigation-bar-row {
	border-radius: 8px;
	display: table;
	font-size: 14px;
	color: rgba(139, 154, 165, 1.0);
	width: 100%;
	height: 40px;
	line-height: 40px;
	cursor: pointer;
}

.navigation-bar-row:hover:not(.navigation-bar-row-header) {
	background-color: rgba(250, 250, 250, 1.0);
}

.navigation-bar-row:active:not(.navigation-bar-row-header) {
	transform: translateY(1px);
}

.navigation-bar-row-selected {
	background-color: rgba(255, 255, 255, 1.0);
}

.navigation-bar-row-header {
	font-weight: bold;
	color: rgba(104, 121, 133, 1.0);
	cursor: default;
}


/** @pragmamark - Navigation Bar Cell **/

.navigation-bar-cell {
	display: table-cell;
}

.navigation-bar-cell-icon {
	width: 28px;
}

.navigation-bar-icon {
	display: inline-block;
	width: 18px;
	height: 18px;
	line-height: 18px;
	background-color: rgba(104, 121, 133, 1.0);
	mask-size: cover;
	cursor: pointer;
}

.navigation-bar-icon-product {
	mask-image: url(../../third-party/images/nav-bar-product-clapperboard.svg);
}
.navigation-bar-icon-miscellaneous {
	mask-image: url(../../third-party/images/nav-bar-miscellaneous.svg);
}

.navigation-bar-button {
	cursor: pointer;
}
.navigation-bar-button:active {
	transform: translateY(1px);
}
