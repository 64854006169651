
.app-input-filefield {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	height: auto;
	padding: 0px;
}

.app-input-filefield-row {
	height: 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
	cursor: auto;
	padding-left: 10px;
	padding-right: 10px;
	border-bottom: 1px solid var(--input-border-color);
}
.app-input-filefield-row:last-child {
	border-bottom: 0px;
}

.app-input-filefield-title {
	display: block;
	font-family: var(--app-font-light);
	font-size: var(--font-size-small);
	line-height: 34px !important;
	-webkit-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
	user-select: text;
}
.app-input-filefield-title-hidden {
	display: none;
}

.app-input-filefield-hash {
	display: block;
	font-family: var(--app-font-normal);
	font-size: var(--font-size-normal);
	line-height: 34px !important;
	-webkit-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
	user-select: text;
}
.app-input-filefield-hash-hidden {
	display: none;
}

.app-input-filefield-input {
	flex: 1;
	font-family: var(--app-font-normal);
	font-size: var(--font-size-small);
	border: 0px;
	outline: none;
	max-width: 100% !important;
	line-height: 34px !important;
}

.app-input-filefield-button {
	align-self: center;
	width: 24px;
	height: 24px;
	line-height: 24px;
	background-size: 24px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	cursor: pointer;
}
.app-input-filefield-button:active {
	transform: translate(0px, 1px);
	-webkit-transform: translate(0px, 1px);
}

.app-input-filefield-button-add {
	background-image: url(../../third-party/images/add-circle-outline.svg);
}

.app-input-filefield-button-delete {
	background-image: url(../../third-party/images/remove-circle-outline.svg);
}