:root {
	--at-markdown-view-text-color: rgba(50, 50, 50, 1.0);

	--at-box-border-color: rgba(225, 227, 230, 1);
	--at-markdown-view-code-background-color: rgba(246, 248, 250, 1);
	--at-markdown-view-inline-code-background-color: rgba(230, 232, 234, 1);

	--at-markdown-view-blockquote-left-border-color: rgba(216, 218, 220, 1);

	--at-markdown-view-target-background-color: rgba(255, 228, 196, 1);
}

.at-markdown-view {
	font-size: 16px;
	letter-spacing: 0.5px;
	line-height: 23px;
	color: var(--at-markdown-view-text-color);
	margin-top: 10px;
}

.at-markdown-view-h1 {
	font-size: 28px;
	font-weight: bold;
	letter-spacing: 0.5px;
	margin-top: 40px;
	margin-bottom: 20px;
	border-bottom: 1px solid var(--at-box-border-color);
	padding-bottom: 15px;
}

.at-markdown-view-h2 {
	font-size: 21px;
	font-weight: bold;
	letter-spacing: 0.5px;
	margin-top: 20px;
	margin-bottom: 10px;
}

.at-markdown-view-h3 {
	font-size: 18px;
	font-weight: bold;
	letter-spacing: 0.5px;
	margin-top: 20px;
	margin-bottom: 10px;
}

.at-markdown-view-h4 {
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 0.5px;
	margin-top: 20px;
	margin-bottom: 10px;
}

.at-markdown-view-link {
	display: inline;
}
.at-markdown-view-link:active {
	transform: translate(0px, 1px);
	-webkit-transform: translate(0px, 1px);
}

:target {
	background-color: var(--at-markdown-view-target-background-color);
 }

.at-markdown-view-code {
	white-space: pre;
	tab-size: 4;
	padding: 10px;
	background-color: var(--at-markdown-view-code-background-color);
	font-family: Menlo, Monaco, 'Courier New', monospace;
	font-size: 13px;
	border-radius: 3px;
	margin-top: 10px;
	overflow-x: scroll;
}

.at-markdown-view-inline-code {
	white-space: pre;
	padding: 2px 5px;
	background-color: var(--at-markdown-view-inline-code-background-color);
	font-family: Menlo, Monaco, 'Courier New', monospace;
	font-size: 14px;
	border-radius: 3px;
	overflow-x: scroll;
	display: inline;
}

.at-markdown-view-blockquote {
	padding-left: 10px;
	border-left: 8px solid var(--at-markdown-view-blockquote-left-border-color);
}
