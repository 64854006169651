.home-page-storey {
	height: auto !important;
	background-color: transparent !important;
	display: flex;
	height: 100%;
	width: 100%;
	overflow: hidden;
	overflow-y: auto;
}


.home-page-header {
	margin: auto;
	text-align: center;
	font-size: 38px;
	font-weight: 600;
	color: var(--text-lightest-color);
}

.home-page-header-hightlighter {
	color: var(--text-hightlighter-lighter-color);
}


.home-page-subheader {
	margin: auto;
	text-align: center;
	font-size: 20px;
	color: var(--text-lighter-color);
}


.home-page-feature-header {
	margin: auto;
	text-align: center;
	font-family: var(--app-font-light);
	font-size: 34px;
	color: var(--text-lightest-color);
}

.home-page-feature-summary {
	width: 80%;
	max-width: 700px;
	margin: auto;
	text-align: center;
	font-size: 16px;
	color: var(--text-lighter-color);
}


.feature-row {
	display: flex;
	flex-direction: row;
	align-items: top;
	gap: 20px;
	width: 850px;
	min-height: 290px;
	margin: auto;
	background-color: rgba(255, 255, 255, 0.5);
	backdrop-filter: blur(25px);
	padding: 25px;
	border-radius: 8px;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	font-size: 16px;
}


.feature-row-even {
	flex-direction: row-reverse;
}


.feature-column-text {
	font-family: var(--mulish-light-font);
	flex: 1;
}
.feature-column-text > div {
	margin-bottom: 8px;
}

.feature-column-text-title {
	font-family: var(--app-font-bold);
	font-size: 18px;
}


.feature-column-image {
	flex: 1;
}


.feature-easy-integration-artwork {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-image: url(../../assets/images/homepage.jpg);
}

.feature-macbook-frame {
	width: 100%;
	height: 100%;
	padding: 8px 24px 26px 24px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	background-image: url(../../assets/images/macbook-frame.svg);
}

.home-page-feature-appupdatepopup-preview {
	width: 100%;
	height: 100%;
	border: 0;
}

.home-page-feature-appupdatepopup-button-container {
	display: flex;
	flex: 1;
	flex-direction: row;
	justify-content: start;
	margin-top: 65px;
}
