
.app-list-table {
	display: flex;
	flex-direction: column;
	width: 100%;
}


.app-list-row {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 54px;
	line-height: 54px;
	border-top: 1px solid var(--table-border-color);
}

.app-list-row:hover:not(.app-list-row-header) {
	background-color: var(--table-row-hover-color);
	cursor: pointer;
}

.app-list-row:last-child {
	border-bottom: 1px solid var(--table-border-color);
}

.app-list-row-header {
	height: 48px;
	line-height: 48px;
	font-weight: 600;
}


.app-list-cell {
	display: flex;
	align-items: center;
	flex: 1;
	padding: 0px 20px;
	font-size: var(--font-size-normal);
	color: var(--table-title-color);
	height: 100%;
	overflow: hidden;
}
