.menu-bar {
	width: calc(100vw - 140px);
	min-width: calc(100vw - 140px);
	max-width: calc(100vw - 140px);
	height: 70px;
	min-height: 70px;
	max-height: 70px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: right;
	gap: 50px;
	padding-right: 8px;
	user-select: none;
}

.menu-bar-item {
	height: auto;
	color: rgba(255, 255, 255, 1);
	font-family: var(--app-font-bold);
	font-size: 14px;
	cursor: pointer;
}

.menu-bar-item:hover {
	color: var(--text-hightlighter-lighter-color)
}

.menu-bar-item:active {
	transform: translate(0px, 1px);
	-webkit-transform: translate(0px, 1px);
}

.menu-bar-language-input {
	width: fit-content;
	background-color: transparent;
	border: 0px;
}

.menu-bar-language-input .app-select-field-title {
	color: #FFFFFF;
}
.menu-bar-language-input:hover .app-select-field-title {
	color: var(--input-value-color);
}

.menu-bar-language-input-icon {
	display: inline-block;
	width: 18px;
	height: 18px;
	background-color: #FFFFFF;
	mask-size: cover;
	-webkit-mask-size: cover;
	mask-image: url(../../third-party/images/localization-language.svg);
}
.menu-bar-language-input:hover .menu-bar-language-input-icon {
	background-color: var(--input-value-color);
}