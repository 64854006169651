
.app-input-textfield {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
	cursor: auto;
}

.app-input-textfield-title {
	display: block;
	font-family: var(--app-font-light);
	font-size: var(--font-size-small);
	line-height: 34px !important;
	-webkit-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
	user-select: text;
	white-space: nowrap;
}
.app-input-textfield-title-hidden {
	display: none;
}

.app-input-textfield-input {
	flex: 1;
	font-family: var(--app-font-normal);
	font-size: var(--font-size-small);
	border: 0px;
	outline: none;
	max-width: 100% !important;
	line-height: 34px !important;
}

.app-input-textfield-input::placeholder {
	color: var(--input-placeholder-color);
}
